import React from "react";
import { graphql } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

import Layout from "components/bespoke/Layout";
import BespokeHero from "components/BespokeHero";
import LinkInternal from "components/LinkInternal";
import NumberedLabel from "components/NumberedLabel";
import Image from "components/Image";

export const query = graphql`
  {
    wp {
      page(id: "/bespoke/", idType: URI) {
        id
        title
        slug
        heroPanel {
          ...HeroPanelFragment
        }
        bespokeOverview {
          overviewPanel {
            heading
            content
            link {
              title
              target
              url
            }
          }
          overviewHighlight {
            heading
            content
            page {
              ... on WP_Page {
                title
                uri
              }
            }
            image {
              ...MediaItem
            }
          }
          overviewImage {
            ...MediaItem
          }
        }
      }
    }
  }
`;

const bespoke = ({ data }) => {
  const { title } = data.wp.page;
  const { heroSlides } = data.wp.page.heroPanel;
  const { overviewPanel, overviewHighlight, overviewImage } =
    data.wp.page.bespokeOverview;
  return (
    <Layout title={title} bespokeHome={true}>
      <div>
        {/* Hero slider */}
        <BespokeHero slides={heroSlides} />
        {/* Intro copy */}
        <div className="md:container mx-6 md:mx-auto py-16 md:py-32 text-center">
          <div className="text-3xl uppercase tracking-wider max-w-xl mx-auto font-bold text-firefly mb-12">
            {overviewPanel.heading}
          </div>
          <div
            className="text-mineral space-y-4 mb-12 max-w-xl mx-auto"
            dangerouslySetInnerHTML={{ __html: overviewPanel.content }}
          ></div>
          <a
            href={overviewPanel.link.url}
            className="text-2xl font-bold uppercase tracking-wide text-mineral inline-flex items-center hover:underline hover:opacity-70"
          >
            {overviewPanel.link.title}
            <BsArrowRight className="text-4xl ml-4 text-firefly" />
          </a>
        </div>
        {/* Text/Image */}
        <div className="bg-stormdust">
          <div className="md:container mx-6 md:mx-auto grid lg:grid-cols-2">
            <div className="flex flex-col space-y-4 md:px-6 lg:px-0 py-20 md:py-28 lg:pr-20 text-white order-2 lg:order-1">
              <NumberedLabel
                color="white"
                number="1"
                label={overviewHighlight.page.title}
              />
              <div className="text-[28px] font-bold uppercase">
                {overviewHighlight.heading}
              </div>
              <div className="">{overviewHighlight.content}</div>
              <LinkInternal
                target={overviewHighlight.page.uri}
                label="Read more"
              />
            </div>
            <div className="flex lg:mr-[calc(-50vw+100%)] max-h-[600px] order-1 lg:order-2 ml-[-1.5rem] mr-[-1.5rem] md:mx-auto">
              <Image image={overviewHighlight.image} className="" />
            </div>
          </div>
        </div>
        {/* Full-width image */}
        <div className="w-full max-h-[600px] flex overflow-hidden">
          <Image image={overviewImage} />
        </div>
        {/* Contact bar */}
      </div>
    </Layout>
  );
};

export default bespoke;
