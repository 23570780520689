import React from "react";
import { graphql } from "gatsby";

import LinkInternal from "./LinkInternal";
import Image from "./Image";

export const fragment = graphql`
  fragment HeroPanelFragment on WP_Page_Heropanel {
    heroSlides {
      heading
      image {
        ...MediaItem
      }
      linkedPage {
        ... on WP_Project {
          id
          title(format: RENDERED)
          uri
          singleProject {
            detail {
              location
              category
            }
          }
        }
      }
    }
  }
`;

const BespokeHero = ({ heading, link, slides }) => {
  return (
    <div className="relative h-[calc(100vh-92px)] md:h-auto z-[-1] md:z-10 top-[-98px] md:top-0 bg-two-tone from-firefly-500 to-white">
      <div className="relative z-10 md:container md:mx-auto">
        <div>
          {slides &&
            slides.map((slide, i) => {
              return (
                <div key={i}>
                  <div className="relative">
                    <div className="absolute w-full px-[36px] md:px-0 md:w-auto top-[40%] md:top-1/3 left-1/2 z-10 leading-tight md:leading-none transform -translate-x-1/2 text-[40px] md:text-6xl text-center tracking-wider font-semibold text-white filter drop-shadow-text min-h-[300px]">
                      {slide.heading}
                    </div>
                    <Image
                      image={slide.image}
                      className="h-[calc(100vh-224px)] md:h-auto"
                    />
                  </div>

                  <div className="md:absolute left-0 bottom-0 py-8 px-12 bg-stormdust text-white w-full max-w-lg">
                    <h3 className="font-bold uppercase text-xl">
                      {slide.linkedPage.title}
                    </h3>
                    <p className="font-light text-sm">
                      {slide.linkedPage.singleProject.detail.location}
                    </p>
                    <div className="flex justify-between items-center">
                      <p className="text-sm">
                        {slide.linkedPage.singleProject.detail.category[0]}
                      </p>
                      <LinkInternal
                        target={slide.linkedPage.uri}
                        label={`Read more`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BespokeHero;
